<template>
  <div class="setting_form p-4 clear">
    <create-deep-link ref="create_deep_link_modal"></create-deep-link>
    <div class="white_box">
      <!--Head-->
      <div v-if="false" class="header">
        <div class="d-flex align-items-center justify-content-between deeplinks-alert">
          <p>Boost your conversion rate with Deep Links <i class="far fa-question-circle"></i></p>
          <button class="btn---cta btn-blue with-shadow btn-round   btn-bold">
            <span>Request an App</span>
          </button>
        </div>
        <div class="d-flex mt-4 align-items-center justify-content-between business-growth-activity">
          <div class="left d-flex align-items-center justify-content-between side">
            <div class="head">
              <h4>Increase your Amazon Sales</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis risus mi.</p>
            </div>
            <div class="content">
              <img src="/assets/img/deeplinks/aws.png"/>
            </div>
          </div>
          <div class="right d-flex align-items-center justify-content-between side">
            <div class="head">
              <h4>Grow your Youtube channel</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis risus mi.</p>
            </div>
            <div class="content">
              <img src="/assets/img/deeplinks/youtube.png"/>
            </div>
          </div>
        </div>
      </div>
      <!--Body-->
      <div class="body">
        <div class="body-title mt-1">
          <h6>{{ getSelectedDeepLink.name }}</h6>
          <p class="mt-2">{{ getSelectedDeepLink.description }}</p>
        </div>
        <div v-if="getSelectedDeepLink.items && getSelectedDeepLink.items.length" class="body-section mt-4">
          <div class="row apps">
            <div v-for="item in getSelectedDeepLink.items" class="col-lg-4 mb-4 col-md-6">
              <div @click="createDeepLinkModal(item)" class="d-flex block align-items-center justify-content-between">
                <div class="content">
                  <img :src="item.social_icon"/>
                </div>
                <div class="head">
                  <h6>{{ item.name }}</h6>
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import CreateDeepLink from '@/views/pages/setting/DeepLinks/CreateDeepLink'

export default {
  name: 'DeepLinksCommon',
  async created () {
    await this.selectCategory(this.$route.name)
  },
  components: {
    'create-deep-link': CreateDeepLink
  },
  computed: {
    ...mapGetters(['getSelectedDeepLink'])
  },
  methods: {
    ...mapActions(['selectCategory', 'setCreateDeepLink']),
    createDeepLinkModal (item) {
      this.setCreateDeepLink(item)
      this.$refs.create_deep_link_modal.defaultSelectedBrand()
      this.$bvModal.show('modal-create-deep-link')
    }
  }
}
</script>
<style scoped lang="less">
.setting_form {
  border-left: 1px solid #d0d6e5;
  min-height: 132px;
  .white_box {
    .box_inner {
      .header {
        .deeplinks-alert {
          padding: 10px 20px;
          border-radius: 10px;
          background: #f2f6f7;

          p {
            font-weight: bold;
          }
        }

        .business-growth-activity {
          width: 100%;

          .side {
            background-image: linear-gradient(112deg, #43a6ff, #0078e4);
            border-radius: 10px;
            width: 49%;
            height: auto;
            padding: 20px;

            .head {
              h4 {
                color: white;
                font-weight: bold;
                margin-bottom: 10px;
              }

              p {
                color: white;
              }
            }

            .content {
              img {
                width: 120px;
              }
            }
          }

          .left {
            background-image: linear-gradient(112deg, #43a6ff, #0078e4);
          }

          .right {
            background-image: linear-gradient(112deg, #ff8082, #dc2124);
          }
        }
      }

      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }
          p{
            font-size: small;
          }
        }

        .body-section {
          .apps {
            .block {
              border: 1px solid #d0d6e5;
              border-radius: 10px;
              padding: 15px 10px;
              cursor: pointer;
              min-height: 90px;
              .content {
                img {
                  width: 50px;
                }
              }

              .head {
                padding-left: 15px;
                h6 {
                  font-weight: bold;
                  font-size: small;
                }

                p {
                  font-size: small;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

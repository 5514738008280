<template>
  <b-modal modal-class="modal_basic modal-create-deep-link" id="modal-create-deep-link" hide-footer hide-header>
    <img @click="$bvModal.hide('modal-create-deep-link')" class="close_modal" src="/assets/img/cross.svg" alt="">
    <div class="basic_form modal_content">
      <div class="text-center heading">
        <!-- <i class="far fa-question-circle"></i> -->
        <img :src="getCreateDeepLink.social_icon"/>
        <h6>{{ getCreateDeepLink.name }} | Deep link integration</h6>
        <p>{{ getCreateDeepLink.description }}</p>
      </div>
      <div class="body">
        <div class="brands  mt-5">
          <p>Deep links supported by {{ getCreateDeepLink.name }}</p>
          <div class="brands-list mt-4">
            <div v-for="(item,index) of getCreateDeepLink.brands" @click="selectBrand(item,index)"
                 :class="{'selected':item.selected}" class="brand-tags mr-2">
              {{ item.name }}
            </div>
          </div>
          <hr class="mt-4">
        </div>
        <div class="url-format mt-4">
          <p class="label"><i class="fas fa-link"></i> URL Format</p>
          <div class="url-type mt-3">{{ urlConcatObj.url }}<span>{{ urlConcatObj.concat_key }}</span></div>
        </div>
        <hr class="mt-5">
        <div class="mt-4 os-supported">
          <p class="label"><i class="fas fa-mobile"></i> OS Supported</p>
          <div class="d-flex mt-4 os-list">
            <p class="d-flex justify-content-center"
               v-if="getCreateDeepLink.supported && getCreateDeepLink.supported.android"><img class="mr-2"
                                                                                              src="@/assets/img/os/android-line.png"/><small
              class="m-auto">Android</small></p>
            <p class="ml-4 d-flex justify-content-center"
               v-if="getCreateDeepLink.supported && getCreateDeepLink.supported.ios"><img class="mr-2"
                                                                                          src="@/assets/img/os/apple-line.png"/>
              <small class="m-auto">iOS</small></p>
          </div>
        </div>
        <hr class="mt-5">
      </div>
      <div class="d-flex mt-4 justify-content-end footer">
        <button type="button" @click="$bvModal.hide('modal-create-deep-link')"
                class="mr-2 btn---cta light-blue btn-round  btn-bold"><span>Close</span></button>
        <button @click="createDeepLink()" class="btn---cta btn-blue with-shadow btn-round  btn-bold"><span>Create Deep Link</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'CreateDeepLink',
  data() {
    return {
      urlConcatObj: {}
    }
  },
  async created() {
  },
  computed: {
    ...mapGetters(['getCreateDeepLink', 'getSaveLink'])
  },
  methods: {
    sanitizeUrl(url){ return url.replace(/\\/g, '')},
    selectBrand(item, index) {
      item.selected = true
      this.isSingleSelect(index)
      this.urlConcatObj = {
        url: this.sanitizeUrl(item.url),
        concat_key: item.concat ? item.concat_key : ''
      }
    },
    isSingleSelect(index) {
      this.getCreateDeepLink.brands.filter((item, i) => item.selected = (index == i))
    },
    defaultSelectedBrand() {
      this.urlConcatObj = {
        url: this.sanitizeUrl(this.getCreateDeepLink.brands[0].url),
        concat_key: this.getCreateDeepLink.brands[0].concat ? this.getCreateDeepLink.brands[0].concat_key : ''
      }
    },
    createDeepLink() {
      if (!this.isFeatureAllowedInPlan('deep_linking')) {
        this.$bvModal.show('upgrade-subscription-modal')
        return
      }
      this.createNewLink(this.urlConcatenation(this.urlConcatObj.url) + this.urlConcatObj.concat_key)
    }
  }
}
</script>
<style lang="less">
#modal-create-deep-link {
  .modal-content {
    width: 800px;
    margin: auto;
  }

  .modal_content {
    .heading {
      img {
        width: 100px;
        padding: 15px;
      }

      h6 {
        letter-spacing: 0.6px;
        color: #1f216e;
        font-size: large;
      }

      p {
        font-size: 0.9rem;
        color: #676887;
      }
    }

    .body {

      .brands, .url-format, .os-supported {
        p {
          color: #1f216e;
          font-weight: bold;
          font-size: 0.9rem;
        }
      }

      .brands {
        .brands-list {
          display: flex;
          flex-wrap: wrap;

          .brand-tags {
            background-color: rgba(69, 162, 255, 0.1);
            border: none;
            display: block;
            padding: 10px 18px;
            font-size: 0.8rem;
            border-radius: 30px;
            color: #1d8bfa;
            font-weight: bold;
            margin-bottom: 10px;
            cursor: pointer;
          }

          .selected {
            background-color: #1d8bfa;
            color: white !important;
          }
        }
      }

      .url-format {
        .url-type {
          border-radius: 10px;
          border: solid 1px #9ea9c4;
          padding: 12px 20px;
          font-size: 0.95rem;
          letter-spacing: 0.8px;

          span {
            color: #1d8bfa;
          }
        }
      }

      .os-supported {
        .os-list {
          p {
            font-size: 0.95rem;
            font-weight: lighter;
          }
        }
      }
    }
  }
}
</style>
